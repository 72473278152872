<template>
  <v-card>
    <v-card-title>Jam Type</v-card-title>
    <v-card-text>
      <v-text-field
          v-model="jamtype.name"
          label="Name"
          ref="typeName"
      />
    </v-card-text>
    <v-card-text>
      <v-select
          label="Group"
          :items="groups"
          v-model="jamtype.group"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          @click="$emit('save')"
      >
        Save
      </v-btn>
      <v-btn
          v-if="jamtype.id !== 'new'"
          @click="confirmDeleteDialog = true"
          color="primary"
      >Delete</v-btn>
    </v-card-actions>
    <v-dialog
        v-model="confirmDeleteDialog"
        width="450"
    >
      <ConfirmDialog
          title="Are you sure you want to delete this jam type?"
          subtitle="This will remove all prompts assiciated with they Jam Type."
          @confirm="$emit('delete')"
          @cancel="confirmDeleteDialog = false"
          :loading="loading"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import ConfirmDialog from '@/components/dialogs/Confirm';

  export default {
    name: 'AdminTypeEdit',
    props: {
      jamtype: {
        type: Object,
        default:  () => ({ name: '', group: '', id: '' })
      },
      groups: {
        type: Array
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    components: {
      ConfirmDialog
    },
    data () {
      return {
        confirmDeleteDialog: false
      }
    },
    methods: {
      handleSave () {
        console.log('save me');
      }
    }
  }
</script>