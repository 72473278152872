<template>
  <v-card>
    <v-card-title class="secondary white--text text-h5">
      Prompts Admin
      <v-spacer></v-spacer>
      <v-select
          label="Language"
          :items="langs"
          item-text="lbl"
          item-value="val"
          v-model="lang"
          color="#FFFFFF"
          :loading="this.api.loading"
      />
    </v-card-title>
    <v-row
        class="pa-4"
        justify="space-between"
    >
      <v-col cols="12" lg="5" sm="12">
        <v-treeview
            :items="items"
            :open.sync="open"
            :active.sync="active"
            activatable
            color="warning"
            open-on-click
            transition
            @update:active="handleActive"
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="!item.children">
              mdi-arrow-right-thin-circle-outline
            </v-icon>
          </template>
        </v-treeview>

        <v-divider></v-divider>

        <v-btn class="ma-6" @click="addType">
          <v-icon>mdi-plus</v-icon>
          type
        </v-btn>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col
        class="d-flex flex-column">
        <v-scroll-y-transition mode="out-in">
          <div
              v-if="!selected"
              class="text-h6 grey--text text--lighten-1 font-weight-light pt-6 mx-auto"
              style="align-self: center;"
          >
            Select a type
          </div>
          <v-card
              flat
              v-else>
            <v-toolbar
                dense
                elevation="1"
                class="mb-7"
                >
              <v-toolbar-title>{{ selected.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                  @click="handleEditType"
                  color="black"
                  icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>

            <v-progress-circular
                v-show="loadingPrompts"
                indeterminate
                color="primary"
            />
            <PromptRow
                v-for="(input, k) in prompts" :key="k"
                v-model="input.text"
                @save="handleSaveRow(k)"
                @delete="openPromptDeleteConfirm(k)"
            />
            <v-row class="ma-0">
              <v-col cols="12">
                <v-text-field
                    v-model="newRow"
                    type="text"
                    filled
                    label="New Prompt"
                    @keydown.enter="handleNewRow"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-dialog
        v-model="comfirmPromptDelete"
        width="450"
    >
      <ConfirmDialog
          title="Are you sure that you want to delete this prompt?"
          @confirm="handlePromptDelete"
          @cancel="comfirmPromptDelete = false"
          :loading="this.api.loading"
      />
    </v-dialog>
    <v-dialog
        v-model="typeDialog"
        width="450"
    >
      <AdminTypeEdit
        :jamtype="selected !== null ? selected: {}"
        :groups="groups"
        @delete="handleDelete"
        @save="handleSave"
        :loading="api.loading"
      />
    </v-dialog>
  </v-card>
</template>
<script>
  import Http from '@/api/Http';
  import ConfirmDialog from '@/components/dialogs/Confirm';
  import PromptRow from '@/components/PromptRow';
  import AdminTypeEdit from '@/components/AdminTypeEdit';

  export default {
    name: 'AdminJamTypes',
    components: {
      AdminTypeEdit,
      ConfirmDialog,
      PromptRow
    },
    data() {
      return {
        api: Http.use('data').fail(this.globalFail),
        langs: [{lbl: 'English', val: 'en'}],
        lang: 'en',
        active: [],
        confirmDeleteDialog: false,
        comfirmPromptDelete: false,
        open: [],
        items: [],
        types: {},
        newRow: '',
        selected: null,
        groups: ['travel', 'journal', 'event', 'person'],
        avatarSize: 235,
        loadingPrompts: false,
        prompts: [],
        selectedPrompt: null,
        typeDialog: false,
        novo: {
          id: 'new',
          name: 'new',
          group: ''
        },
        avatars: {
          commonAvatarProperties: {
            hoverDisabled: false,
          },
          luggageAvatar: {
            color: "accentBackground",
            text: this.$t('homePage.travel'),
            image: require('@/assets/Luggage.svg'),
          },
          notebookAvatar: {
            color: "primaryAction",
            text: this.$t('homePage.journal'),
            image: require('@/assets/Notebook.svg')
          },
          partyAvatar: {
            color: "secondaryBackgroundEmphasis",
            text: this.$t('homePage.specialEvent'),
            image: require('@/assets/Party.svg'),
          },
          crownAvatar: {
            color: "accentBackground",
            text: this.$t('homePage.specialPerson'),
            image: require('@/assets/Crown.svg'),
          },
        },
      }
    },
    created () {
      this.index();
    },
    methods: {
      globalFail (evt) {
        if (evt.response.status === 401) {
          this.$emit('notify', {
            visible: true,
            color: 'red accent-2',
            text: 'Unauthorize',
            icon: 'mdi-alert-circle-outline'
          });
        }
      },
      handleEditType () {
        this.typeDialog = true;
      },
      openPromptDeleteConfirm (id) {
        this.selectedPrompt = this.prompts[id];
        this.comfirmPromptDelete = true;
      },
      handlePromptDelete () {
        this.api.delete('/admin/prompts/' + this.selectedPrompt._id)
            .then(() => {
              this.$emit('notify', {
                visible: true,
                color: 'success',
                text: 'Prompt deleted',
                icon: 'mdi-check-circle'
              });
              this.comfirmPromptDelete = false;
              this.selectedPrompt = null;
              this.loadPrompts();
            })
            .catch(err => {
              console.log(err);
            })
      },
      handleSaveRow (id) {
        this.api.put('/admin/prompts/' + this.prompts[id]._id, this.prompts[id])
            .then(() => {
              this.$emit('notify', {
                visible: true,
                color: 'success',
                text: 'Prompt saved',
                icon: 'mdi-check-circle'
              });
            })
            .catch(err => {
              this.$emit('notify', {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error,
                icon: 'mdi-alert-circle-outline'
              });
            })

      },
      handleNewRow () {
        this.api.post('/admin/prompts/', {text: this.newRow, lang: this.lang, typeId: this.selected.id})
            .then(() => {
              this.$emit('notify', {
                visible: true,
                color: 'success',
                text: 'Prompt created',
                icon: 'mdi-check-circle'
              });
              this.loadPrompts();
              this.newRow = '';
            })
            .catch(err => {
              this.$emit('notify', {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error,
                icon: 'mdi-alert-circle-outline'
              });
            })
      },
      handleDelete() {
        this.api.delete('/admin/types/' + this.selected.id)
            .then(() => {
              this.confirmDeleteDialog = false;
              this.$emit('notify', {
                visible: true,
                color: 'success',
                text: 'Type deleted',
                icon: 'mdi-check-circle'
              });
              this.typeDialog = false;
              this.selected = null;
              this.index();
            })
            .catch(err => {
              console.log(err)
            });
      },
      handleSave() {
        this.api.put('/admin/types/' + this.selected.id, this.selected)
            .then(() => {
              this.$emit('notify', {
                visible: true,
                color: 'success',
                text: this.selected.id === 'new' ? 'Type added': 'Type updated',
                icon: 'mdi-check-circle'
              });
              this.typeDialog = false;
              this.selected = null;
              this.active = [];
              this.index();
            })
            .catch(err => {
              this.$emit('notify', {
                visible: true,
                color: 'red accent-2',
                text: err.response.data.error,
                icon: 'mdi-alert-circle-outline'
              });
            });
      },
      loadPrompts () {
        this.loadingPrompts = true;
        this.prompts = [];
        this.api.get('/admin/prompts/' + this.selected.id)
            .then(promps => {
              this.prompts = promps;
              this.loadingPrompts = false;
            })
            .catch(err => {
              this.loadingPrompts = false;
              this.$emit('notify', {
                visible: true,
                color: 'red accent-2',
                text: err.message,
                icon: 'mdi-alert-circle-outline'
              });
            })
      },
      handleActive(evt) {
        this.selected = (evt.length) ? this.types[evt[0]] : null;
        if (this.selected !== null) {
          this.loadPrompts();
        }
      },
      addType() {
        this.selected = { name: '', id: 'new', group: '', lang: this.lang };
        this.typeDialog = true;
      },
      index () {
        this.api.get('/admin/types', {lang: this.lang})
            .then(res => {
              this.items = res;
              res.forEach(item => {
                this.types[item.id] = item;
              });

              let ret = {};
              if (res) {
                  res.forEach(type => {
                      if (ret[type.group] === undefined) {
                          ret[type.group] = { name: type.group, id: type.group, children: [] };
                      }
                      ret[type.group].children.push({id: type.id, name: type.name, group: type.group});
                  })
              }
              this.items = Object.values(ret);
            })
            .catch(err => {
              console.log(err)
            });
      }
    }
  }
</script>