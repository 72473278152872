<template>
  <v-row class="ma-0" style="border-bottom: 1px solid #c0c0c0;">
    <v-col>
      <span @click="editing=true" v-show="!editing">
        {{ content }}
      </span>
      <v-text-field
          v-model="content"
          type="text"
          label="Prompt"
          v-show="editing"
          @keydown.enter="handleSave"
          @keydown.esc="editing=false"
          @input="handleInput"
      >
        <template v-slot:append-outer>
<!--          <v-btn icon>-->
<!--            <v-icon small>mdi-arrow-up</v-icon>-->
<!--          </v-btn>-->
<!--          <v-btn icon>-->
<!--            <v-icon small>mdi-arrow-down</v-icon>-->
<!--          </v-btn>-->
          <v-btn icon @click="$emit('delete')">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    name: 'PromptRow',
    props: {
      value: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        editing: false,
        content: this.value
      }
    },
    methods: {
      handleSave () {
        this.editing = false;
        this.$emit('save');
      },
      handleInput () {
        this.$emit('input', this.content);
      }
    }
  }
</script>